@mixin callout-block($box-height, $callout-height, $callout-width) {
  display: block;
  position: relative;
  margin: 20px 0;
  padding: 8px 0 6px;
  border: 1px solid $light-grey;
  border-radius: 2px;
  min-height: $box-height;

  > div {
    padding: 0 20px;
  }

  > .row {
    padding: 0;
  }

  .callout {
    display: block;
    float: left;
    width: $callout-width;
    height: $callout-height;
    background-color: $medium-green;
    border-radius: 0 2px 2px 0;
  }

  .right-arrow {
    width: 21px;
  }
}

.callout-block {
  @include callout-block(53px, 36px, 5px);
  @media only screen and (max-width: 695px) {
    max-width: calc(100vw - 64px);
  }
}

.callout-block-lg {
  @include callout-block(62px, 45px, 4px);
}

