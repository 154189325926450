/* Tbums green checkbox*/
input[type=checkbox] {
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
}
.form-check {
  width: fit-content;
  label {
    margin-top: 2px;
    margin-left: 4px;
    cursor: pointer;
  }
}
select {
  cursor: pointer;
}
