// override bootstrap variables
@import "variables";

// translation of custom file input
$custom-file-text: (
  en: "Browse",
  sv: "Bläddra"
);

// import bootstrap
@import "bootstrap/scss/bootstrap";

@import "ngx-toastr/toastr-bs5-alert";

@import "@ng-select/ng-select/themes/default.theme.css";

// import custom styles
@import "global";
@import "callout";
@import "callout-block";
@import "buttons";
@import "badge";
@import "card";
@import "table";
@import "input";

html, body {
  min-height: 100vh;
}
