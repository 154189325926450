@import "assets/scss/variables";
table tbody tr:nth-child(2n + 1) {
}
table {
  white-space: nowrap;

  thead th {
    &:first-child { padding-left: 25px; }
    &:last-child { padding-right: 25px; }
  }

  tbody tr {
    height: 52px;
    border-top: 1px solid #e5e7eb;
    td {
      vertical-align: middle;
      &:first-child {  padding-left: 25px; }
      &:last-child { padding-right: 25px; }
    }
    &:hover { background-color: $material-hover-primary; }
  }
  th {
    border-top: none !important;
    border-bottom-width: 1px !important;
  }
}

.table th, .table td {
  border-color: $material-border-grey;
}
.table thead th {
  border-color: $material-border-grey;
}
