// Nobina colors
$dark-blue-green:     #043D0C;
$medium-green:        #39A935;
$sick-green:          #a8c629;
$light-green:         #dff0d0;
$cerulean:            #00a1de;
$peacock-blue:        #0046a3;
$macaroni-and-cheese: #f2af32;
$wheat:               #fdde8f;
$table-green:         #eff7e8;

// ??
$badge-medium-green:  #e2efe1;
$badge-light-green:   #f5f9f5;

// web colors
$redish:              #D73534;
$pinkish:             rgba(255, 137, 137, 0.6);
$darkish-pink:        #ea5455;
$medium-pink:         #ee66a5;
$black:               #000;
$blackish:            #000100;
$greyish-brown:       #4d4d4d;
$dark-grey:           #565656;
$greyish:             #b1b1b1;
$light-grey:          #dfdfdf;
$greenish:            #f7fff6;
$whitish:             #f8f8f8;
$white:               #fff;

// Material
$material-border-grey:            #e5e7eb;
$material-hover-grey:             #f6f6f6;
$material-hover-selected-grey:    #d8d8d8;
$material-hover-primary:          #f7f8f7;
$material-card-green:             #e9f5e9;
$material-card-selected:          #e0e0e0;
$material-card-hover-green:       #dbefda;
$material-font-color:             #000000de;
$material-card-box-shadow:        0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;

//override bootstrap defaults
$primary:             $dark-blue-green;
$secondary:           $greyish-brown;
$info:                $medium-green;
$light:               $light-grey;

$body-bg:             $white;
$body-color:          $black;
$link-color:          $medium-green;
$form-select-bg:      white;
$input-bg:            white;

$font-family-base: Roboto, "Helvetica Neue", sans-serif;
$font-family-serif:   Georgia, Times, "Times New Roman", serif;
$font-size-base:      1rem;
$font-size-xxs:       $font-size-base * 0.68;
$font-size-xs:        $font-size-base * 0.75;
$font-size-sm:        $font-size-base * 0.8125;
$h1-font-size:        $font-size-base * 2.65;
$h2-font-size:        $font-size-base * 1.75;
$h3-font-size:        $font-size-base * 1.25;
$h4-font-size:        $font-size-base;

$border-color:        $greyish;
$border-radius:       4px;

$input-color:                           $blackish;
$input-border-color:                    $greyish;
$input-placeholder-color:               $greyish;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1536px,
  xxxxl: 1620px,
);

@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
