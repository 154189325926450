@mixin bs-callout($color) {
  display: block;
  max-width: 450px;
  margin: 20px 0;
  padding: 10px 20px;
  border: 1px solid $light-grey;
  border-radius: 2px;
  border-left: 7px solid $color;
}

.bs-callout-primary {
  @include bs-callout($primary);
}

.bs-callout-secondary {
  @include bs-callout($secondary);
}

.bs-callout-danger {
  @include bs-callout($danger);
}

.bs-callout-warning {
  @include bs-callout($warning);
}

.bs-callout-info {
  @include bs-callout($info);
}

.bs-callout-success {
  @include bs-callout($success);
}
