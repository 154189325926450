h1 {
  color: $material-font-color;
  font-size: 1.875rem;
  font-weight: 400;
}

h2 {
  color: $material-font-color;
  font-weight: 400;
}

h3 {
  color: $blackish;
  font-weight: 400;
}

h4 {
  color: $material-font-color;
  font-weight: 400;
  margin-bottom: 0;
}

hr {
  border: none;
  height: 1px;
  background-color: $greyish;
}

.blackish {
  color: $blackish;
}

.border-color {
  &.medium-green {
    border-color: $medium-green;
  }
}

.caption {
  font-size: $font-size-sm;
  font-weight: 400;
  color: $blackish;
}

.caption-md {
  font-size: $font-size-sm;
  font-weight: 400;
  color: $material-font-color;
}

.caption-lt {
  font-size: $font-size-sm;
  font-weight: normal;
  color: $material-font-color;
}

.caption-lt-bl {
  font-size: $font-size-sm;
  font-weight: normal;
  color: $blackish;
}

.caption-lt-xs {
  font-size: $font-size-xs;
  font-weight: normal;
  color: $material-font-color;
}

.caption-lg {
  font-size: $font-size-lg;
  font-weight: 400;
  color: $material-font-color;
}

.caption-xlt {
  font-size: $font-size-xs;
  font-weight: 400;
  color: $greyish;
  text-transform: uppercase;
}

.outer-container {
  //padding: 0 20px;
  padding-bottom: 40px;
  @include media-breakpoint-down(lg) {
    padding: 0;
    padding-bottom: 40px;
  }
}

.container {
  max-width: 1200px;
  background-color: white;
  margin: 20px auto;

  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 10px 15px;
  }
}

.main {
  padding: 20px;

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.xxxl-container {
  max-width: map-get($grid-breakpoints, "xxxl");
  margin: 0 auto;
}

.nav-tabs {
  width: fit-content;
  color: #cccccc;
  border: none;
  margin-bottom: 0;
  a.nav-link {
    color: $dark-grey;
    border-radius: unset !important;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: $material-hover-primary;
      border-bottom: 2px solid $material-hover-primary !important;
      transition: none;
    }
    &:focus {
      background-color: $material-hover-primary !important;
      border-bottom: 2px solid $material-hover-primary !important;
    }
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $dark-blue-green;
  border-bottom: 2px solid $dark-blue-green !important;
  &:hover {
    background-color: $material-hover-primary;
    border-bottom: 2px solid $dark-blue-green !important;
  }
  &:focus {
    border-bottom-color: $dark-blue-green !important;
  }
}

a.nav-link.disabled {
  pointer-events: none;
  cursor: default;
  color: $light-grey !important;
}

input:focus,
.form-control:focus,
.btn:focus,
button:focus {
  border-color: $medium-green;
  outline: 2px solid rgba(137, 255, 172, 0.6) !important;
  z-index: 5;
}

.card:focus {
  background-color: $material-hover-grey;
  outline: none;
  z-index: 5;
}

input[type="checkbox"][disabled]:after {
  background-color: #e9ecef;
  opacity: 1;
  cursor: default;
}

:focus {
  outline: 1px solid $light-grey;
}

input.ng-invalid.ng-touched,
input.ng-invalid.ng-touched:focus,
select.ng-invalid.ng-touched,
select.ng-invalid.ng-touched:focus,
label.ng-invalid,
label.ng-invalid:focus {
  border-color: $redish;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 137, 0.6);
  outline: none !important;
}

.divider {
  border-bottom: 1px solid $light-grey;
}

// scrollbar (Chrome, Safari)
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e8e8e8;
}

::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

::-webkit-scrollbar:horizontal{
  width: 4px;
}

// ng-boostrap calendar overides
.ngb-dp-weekday {
  color: $dark-grey !important;
  font-size: 11px;
  font-style: normal !important;
}

.ngb-dp-day {
  font-size: 13px;
  font-style: normal !important;
}

.red-text {
  color: $redish;
}

@media only screen and (max-width: 1050px) {
  .notificationModal .modal-dialog {
    margin-bottom: 150px;
  }
}
