@import 'assets/scss/_variables.scss';

.tb-card-header {
  padding: 2px 0 0 10px;
}

.card-row {
  margin: 0;
}

.card-col {
  @media only screen and (max-width: 706px) {
    flex-grow: 1;
  }
  padding: 0;
}

.card {
  margin: 20px 10px;
  @media only screen and (max-width: 706px) {
    width: calc(100vw - 20px);
  }
  width: 328px;
  cursor: pointer;
  &:hover {
    background-color: $material-hover-grey;
  }
}

.card-stops {
  padding: 25px 0;
}

.card-footer {
  padding: 13px 24px;
  background-color: transparent;
  border-top: 1px solid $light-grey;
  color: $black;
}

.right-arrow {
  width: 21px;
  vertical-align: initial;
}

.tb-card-lg {
  border: 1px solid $medium-green;
  width: 100%;
  border-radius: 6px;
}
