@mixin tb-badge($width, $height, $font-size, $background-color) {
  width: $width;
  height: $height;
  overflow: hidden;
  line-height: $height;
  color: $white;
  background-color: $background-color;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  font-size: $font-size;
  font-weight: 400;
}

.tb-badge {
  @include tb-badge(57px, 33px, $font-size-base, $dark-blue-green);
}

.tb-badge-lg {
  @include tb-badge(81px, 47px, 20px, $dark-blue-green);
}

.tb-badge-medium-green {
  @include tb-badge(unset, 33px, $font-size-base, $badge-medium-green);
  color: $black;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  white-space: nowrap;
}
.tb-badge-light-green {
  @include tb-badge(unset, 33px, $font-size-base, $badge-light-green);
   color: $black;
   font-weight: normal;
   padding-left: 14px;
   padding-right: 14px;
   white-space: nowrap;
}
