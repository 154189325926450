// standard buttons

.btn-inactive {
  color: $greyish;
  background-color: $white;
  border: solid 1px $greyish;
}

// rounded buttons
@mixin tb-button($color, $alpha) {
  width: 90px;
  height: 21px;
  line-height: 17px;
  border-radius: 11px;
  border: solid 1px $color;
  color: $color;
  background-color: rgba($color, $alpha);
  font-size: 11px;
  cursor: pointer;
}

.tb-button-primary {
  @include tb-button($primary, 0);
}

.tb-button-info {
  @include tb-button($info, 0);
}

.tb-button-danger {
  @include tb-button($danger, 0.18);
}

.btn-min-width {
  min-width: 70px;
}

.small-button {
  min-width: 45px;
  height: 21px;
  padding: 0 12px;
  line-height: 18px;
  font-size: 11px;
  color: $dark-blue-green;
  background-color: white;
  border-radius: 11px;
  border: solid 1px $medium-green;
  cursor: pointer;

  &.active {
    background-color: $light-green;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.xsmall-button {
  width: 45px;
  height: 21px;
  padding: 0 12px;
  line-height: 18px;
  font-size: 11px;
  color: $dark-blue-green;
  background-color: white;
  border-radius: 11px;
  border: solid 1px $medium-green;
  cursor: pointer;
}

.btn-outline-primary {
  background-color: white;
}
